
import { Component, Vue } from "nuxt-property-decorator";
import Header from "~/components/organisms/nav/Header.vue";
import Footer from "~/components/organisms/nav/Footer.vue";
import RouterLink from "~/components/atoms/text/RouterLink.vue";

@Component({
  components: {
    Header,
    Footer,
    RouterLink,
  },
  props: ["error"],
})
export default class ErrorLayout extends Vue {
  head() {
    return {
      htmlAttrs: { lang: "de" },
    };
  }
}
