
import { Component, Vue } from "nuxt-property-decorator";
import Header from "~/components/organisms/nav/MicroHeader.vue";
import Footer from "~/components/organisms/nav/Footer.vue";
import { tabExitHandler } from "~/operations/meta-helper-functions";

@Component({
  components: {
    Header,
    Footer,
  },
})
export default class CheckoutLayout extends Vue {
  // Note: not working currently from nuxt.config so adding the lang additionally in here
  head() {
    return {
      htmlAttrs: { lang: "de" },
    };
  }

  mounted() {
    this.detectUserLeftTab();
  }
  detectUserLeftTab() {
    tabExitHandler(this);
  }
}
