
import { Component, Vue } from "nuxt-property-decorator";
import Header from "~/components/organisms/nav/Header.vue";
import Footer from "~/components/organisms/nav/Footer.vue";
import { tabExitHandler } from "~/operations/meta-helper-functions";

@Component({
  components: {
    Header,
    Footer,
  },
})
export default class ShopLayout extends Vue {
  head() {
    return {
      htmlAttrs: { lang: "de" },
    };
  }
  mounted() {
    this.detectUserLeftTab();
  }
  detectUserLeftTab() {
    tabExitHandler(this);
  }
}
